/* eslint-disable camelcase */
bcl.c.mybProfile = {
  init: function (user) {
    const login = bcl.profile.login;
    if (login === "true") {
      const mybComponent = document.querySelector(".c-myb-content");
      const mybProfileStatusComponent = document.querySelector(".c-myb-profile-status");
      const mybMenu = document.querySelector(".c-myb-menu");
      if (mybComponent) {
        bcl.c.mybProfile.getInfo(mybComponent, user);
      } else if (login && mybProfileStatusComponent) {
        bcl.c.mybProfile.getInfo(mybProfileStatusComponent, user);
      } else if (login && mybMenu) {
        bcl.c.mybProfile.getInfo(mybMenu, user);
      }
    }
  },

  getInfo: function (component, user) {
    if (!component) {
      return;
    }

    if (bcl.profile.mapValues.data) {
      return;
    }

    this.initialize(user);
  },

  initialize: function (ch_data) {
    if (ch_data) {
      const data = {};
      data.bookingNumber = ch_data["myb-bookingNumber"];
      data.name = ch_data["myb-name"];
      data.surname = ch_data["myb-surname"];
      data.level = ch_data["myb-level"];
      data.identifier = ch_data["myb-identifier"];
      data.discount = ch_data["myb-discount"];
      data.datetime = ch_data["datetime"];
      data.federationId = ch_data["federationId"];
      data.userCreationDate = ch_data["myb-userCreationDate"];
      bcl.profile.mapValues.data = data;
    }
    bcl.c.mybProfile.callFunctionsComponents();
  },

  callFunctionsComponents: function () {
    bcl.c.mybProfileStatus.init();
    bcl.c.mybContent.init();
    bcl.c.mybMenu.init();
  },
};
