bcl.c.mybReservations = {
  props: {
    dynamicClass: ".c-myb-reservations.dynamicLoading",
    className: "c-myb-reservations",
    reservations: [],
    mapping: {
      name: { prop: "Hotel.Name" },
      startDate: { prop: "RoomStay.StartDate", format: "Date" },
      endDate: { prop: "RoomStay.EndDate", format: "Date" },
      status: { prop: "status" },
      validity: { prop: "RoomStay.EndDate", format: "Date" },
      discount: { prop: "CancelPolicy.CancelTimeIn" },
      bonus: { prop: "CancelPolicy.CancelTimeIn" },
    },
    valueNames: [],
    data: null,
    list: null,
  },

  callback: function (res) {
    bcl.c.mybReservations.parseValues(res.response);

    if (bcl.c.mybReservations.props.$container) {
      bcl.c.mybReservations.initList();
    }
  },

  init: function () {
    const $component = document.getElementsByClassName(bcl.c.mybReservations.props.className);

    if (!$component || !$component[0]) {
      return;
    }

    bcl.u.loadStyleSheet("/etc.clientlibs/barcelo/clientlibs/clientlib-mybReservations.css", bcl.c.mybReservations.props.dynamicClass);

    bcl.c.mybReservations.props.$container = $component[0];

    if (bcl.c.mybReservations.props.data) {
      bcl.c.mybReservations.initList();
    }
  },

  initList: function () {
    const $list = bcl.c.mybReservations.props.$container.getElementsByClassName("list")[0],
      options = {
        valueNames: bcl.c.mybReservations.props.valueNames,
        item: $list.innerHTML
          .replace(/(?:\r\n|\r|\n)/g, "")
          .replace(/>(\s)+</g, "><")
          .replace(/(\s)+<tr>/g, "<tr>"),
        page: 10,
        pagination: {
          innerWindow: 1,
          left: 0,
          right: 0,
          paginationClass: "pagination",
        },
      };

    $list.innerHTML = "";

    bcl.c.mybReservations.props.list = new window.List("tableID", options);

    if (bcl.c.mybReservations.props.data) {
      bcl.c.mybReservations.addData();
    }

    bcl.c.mybReservations.initPagination();
  },

  initPagination: function () {
    const $pagination = bcl.c.mybReservations.props.$container.getElementsByClassName("table-pagination");

    if (!$pagination || !$pagination[0]) {
      return;
    }

    bcl.c.mybReservations.props.$containerPagination = $pagination[0];

    bcl.c.mybReservations.initElementPagination("jPaginateNext", 1);
    bcl.c.mybReservations.initElementPagination("jPaginateBack", -1);
  },

  initElementPagination: function (className, count) {
    const $node = bcl.c.mybReservations.props.$containerPagination.getElementsByClassName(className);

    if (!$node || !$node[0]) {
      return;
    }

    $node[0].addEventListener("click", function () {
      const list = bcl.c.mybReservations.props.$containerPagination.querySelectorAll("ul.pagination li");

      for (let i = 0; i < list.length; i++) {
        if (bcl.u.containsClass(list[i], "active") && i + count != list.length && i + count > -1) {
          const event = document.createEvent("HTMLEvents");
          event.initEvent("click", true, false);
          list[i + count].dispatchEvent(event);
          break;
        }
      }
    });
  },

  addData: function () {
    bcl.c.mybReservations.props.list.add(bcl.c.mybReservations.props.data);
  },

  parseValues: function (json) {
    const data = JSON.parse(json),
      reservations = data.reservations,
      results = [];

    for (let i = 0; i < reservations.length; i++) {
      results.push(bcl.c.mybReservations.getValues(reservations[i]));
    }

    bcl.c.mybReservations.props.data = results;
  },

  getValues: function (item) {
    const result = {},
      valueNames = bcl.c.mybReservations.props.valueNames.length;

    for (const key in bcl.c.mybReservations.props.mapping) {
      const obj = bcl.c.mybReservations.props.mapping[key];
      let value = bcl.u.getInData(item, obj.prop);

      if (obj.format && bcl.c.mybReservations["getFormat" + obj.format]) {
        value = bcl.c.mybReservations["getFormat" + obj.format](value);
      }

      result[key] = value;

      if (!valueNames) {
        bcl.c.mybReservations.props.valueNames.push(key);
      }
    }

    return result;
  },

  getFormatDate: function (value) {
    const date = new Date(value);

    return date.toLocaleDateString();
  },
};

bcl.u.docReady(bcl.c.mybReservations.init, true);
