bcl.c.mybProfileStatus = {
  props: {
    dynamicClass: ".c-myb-profile-status.dynamicLoading",
    className: "c-myb-profile-status",
    classNameHeader: ".c-myb-profile-status__header",
    classNameMilestone: "c-progress-bar__milestone",
    classActive: "mod--active",
    classLastActive: "mod--last-active",
  },

  init: function () {
    const $component = document.getElementsByClassName(bcl.c.mybProfileStatus.props.className);

    if (!$component || !$component[0]) {
      return;
    }

    bcl.u.loadStyleSheet("/etc.clientlibs/barcelo/clientlibs/clientlib-mybProfileStatus.css", bcl.c.mybProfileStatus.props.dynamicClass);

    bcl.c.mybProfileStatus.props.$container = $component;

    Array.from($component).forEach((element) => {
      const $node = element.querySelector(bcl.c.mybProfileStatus.props.classNameHeader);

      if (!$node) {
        return;
      }

      bcl.c.mybProfileStatus.props.$containerHeader = $node;
      bcl.c.mybProfileStatus.setData(element, $node);
    });
  },

  setData: function ($component, $containerHeader) {
    bcl.c.mybProfileStatus.update($component, $containerHeader);
  },

  update: function ($component, $containerHeader) {
    bcl.profile.parsePlaceholderInContainer($containerHeader);

    bcl.s.progressBar.updateClass(bcl.profile.mapValues.data.bookingNumber - 1, $component);
  },
};
