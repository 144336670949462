(() => {
  const MyBHero = () => {
    const selectors = {
      dynamicClass: ".c-myb-hero.dynamicLoading",
      component: "myb-hero",
      swiperCarousel: ".cmp-bhg-swiper-js",
      animationCards: ".js-myb-animation-cards",
      animationCardsCard: ".js-animation-cards-card",
      firstLoad: true,
    };

    const init = () => {
      bcl.u.loadStyleSheet("/etc.clientlibs/barcelo/clientlibs/clientlib-myBHero.css", selectors.dynamicClass);

      const parentElement = Array.from(document.getElementsByClassName(selectors.component));
      if (parentElement) {
        parentElement.forEach((element) => {
          window.addEventListener("load", () => {
            const slides = element.querySelectorAll(".swiper-slide");
            let slidesCounter = 0;
            slides.forEach((element) => {
              window.addEventListener("resize", () => {
                if (window.innerWidth > 1024) {
                  // eslint-disable-next-line @typescript-eslint/no-use-before-define
                  activateCarousel(element);
                }
              });

              const number = document.createElement("span");
              number.classList.add("number-slide");

              const result = element.getAttribute("data-swiper-slide-index") * 1 + 1;
              if (result > 10) {
                number.innerHTML = result;
              } else {
                number.innerHTML = "0" + result;
              }
              element.appendChild(number);
            });

            if (slidesCounter > slides.length) {
              slidesCounter = 0;
            }

            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            executeAnimationCards(element);
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            activateCarousel(element);
          });
        });
      }
    };

    const activateCarousel = (element) => {
      const carousel1 = element.querySelector(".js-main-carousel");
      const carousel2 = element.querySelector(".js-second-carousel");

      if (carousel1 && carousel2) {
        const swiperCarousel1 = carousel1.querySelector(selectors.swiperCarousel);
        const swiperCarousel2 = carousel2.querySelector(selectors.swiperCarousel);

        if (swiperCarousel1 && swiperCarousel2) {
          swiperCarousel2.swiper.allowTouchMove = false;

          if (selectors.firstLoad) {
            const realIndex = swiperCarousel1.swiper.realIndex;
            const previousIndex = realIndex - 1;
            const adjustedIndex = previousIndex < 0 ? swiperCarousel1.swiper.slides.length - 1 : previousIndex;
            swiperCarousel2.swiper.slideToLoop(adjustedIndex, 0);
            selectors.firstLoad = false;
          }

          swiperCarousel2.querySelectorAll(".swiper-slide").forEach((slide, index) => {
            slide.style.opacity = index === swiperCarousel2.swiper.realIndex ? 1 : 0;
          });

          swiperCarousel1.swiper.on("transitionEnd", function () {
            const currentSlide = swiperCarousel2.querySelector(".swiper-slide-active");
            if (currentSlide) {
              currentSlide.style.transition = "opacity 1s ease-in-out";
              currentSlide.style.opacity = 0;
            }
            const realIndex = swiperCarousel1.swiper.realIndex;
            const previousIndex = realIndex - 1;
            const adjustedIndex = previousIndex < 0 ? swiperCarousel1.swiper.slides.length - 1 : previousIndex;
            swiperCarousel2.swiper.slideToLoop(adjustedIndex, 0);

            setTimeout(() => {
              const newActiveSlide = swiperCarousel2.querySelector(".swiper-slide-active");
              if (newActiveSlide) {
                newActiveSlide.style.transition = "opacity 1s ease-in-out";
                newActiveSlide.style.opacity = 1;
              }
            }, 200);
          });
        }
      }
    };

    const displayCardsInOrder = (animationCardsCarousel) => {
      const cards = animationCardsCarousel.querySelectorAll(selectors.animationCardsCard);
      if (cards && cards.length == 3) {
        const swiperSlide = animationCardsCarousel.closest(".swiper-slide");
        if (swiperSlide && swiperSlide.classList.contains("swiper-slide-active")) {
          let delay = 0;
          cards.forEach((card) => {
            setTimeout(() => {
              card.classList.add("mod--display-card");
            }, delay);
            delay += 1000;
          });
          delay = 0;
        } else if (swiperSlide && !swiperSlide.classList.contains("swiper-slide-active")) {
          cards.forEach((card) => card.classList.remove("mod--display-card"));
        }
      }
    };

    const executeAnimationCards = (mybHero) => {
      const carousel1 = mybHero.querySelector(".js-main-carousel");
      const swiperCarousel = carousel1.querySelector(selectors.swiperCarousel);
      if (swiperCarousel) {
        if (swiperCarousel.swiper) {
          const animationCardsCarousel = swiperCarousel.querySelectorAll(selectors.animationCards);

          if (animationCardsCarousel && animationCardsCarousel.length > 0) {
            animationCardsCarousel.forEach((animation) => {
              if (!animation.classList.contains("mod--edit")) {
                displayCardsInOrder(animation);
                swiperCarousel.swiper.on("slideChange", function () {
                  // This prevents the ".swiper.on()" method from overlapping
                  setTimeout(() => {
                    displayCardsInOrder(animation);
                  }, 200);
                });
              }
            });
          }
        }
      }
    };

    init();
  };
  document.addEventListener("DOMContentLoaded", MyBHero);
})();
