(() => {
  const MybFeatures2 = () => {
    const selectors = {
      dynamicClass: ".c-myb-features-2.dynamicLoading",
      component: ".myb-features-2",
      content: ".js-myb-features-2",
      tabItem: ".js-myb-features-2-tab-item",
      headerLevel: ".js-myb-features-2-header-level",
      rowSection: ".js-myb-features-2-row-section",
      accordion: ".js-myb-features-2-accordion",
    };

    const cleanTab = (content, tabItem) => {
      content.classList.remove("mod--essential", "mod--special", "mod--unique");
      tabItem.forEach((item) => {
        item.classList.remove("mod--active");
      });
    };

    const addHoverClass = (content, level) => {
      if (content.dataset.accordionClose != "true") {
        content.classList.remove("mod--no-hover");

        if (level.classList.contains("--essential")) {
          content.classList.remove("mod--special-hover", "mod--unique-hover");
          content.classList.add("mod--essential-hover");
        } else if (level.classList.contains("--special")) {
          content.classList.remove("mod--unique-hover", "mod--essential-hover");
          content.classList.add("mod--special-hover");
        } else if (level.classList.contains("--unique")) {
          content.classList.remove("mod--special-hover", "mod--essential-hover");
          content.classList.add("mod--unique-hover");
        }
      }
    };

    const init = () => {
      bcl.u.loadStyleSheet("/etc.clientlibs/barcelo/clientlibs/clientlib-mybFeatures2.css", selectors.dynamicClass);

      const parentElement = Array.from(document.querySelectorAll(selectors.component));
      parentElement.forEach((element) => {
        const content = element.querySelector(selectors.content);
        const tabItem = element.querySelectorAll(selectors.tabItem);
        const headerLevel = element.querySelectorAll(selectors.headerLevel);
        const rowSection = element.querySelectorAll(selectors.rowSection);
        const accordion = element.querySelectorAll(selectors.accordion);

        content.addEventListener("mouseleave", () => {
          content.classList.remove("mod--essential-hover", "mod--special-hover", "mod--unique-hover");
        });

        tabItem.forEach((item) => {
          item.addEventListener("click", () => {
            const type = item.dataset.type;

            cleanTab(content, tabItem);
            content.classList.add("mod--" + type);
            item.classList.add("mod--active");
          });
        });

        headerLevel.forEach((level) => {
          level.addEventListener("mouseenter", () => {
            addHoverClass(content, level);
          });
        });

        rowSection.forEach((section) => {
          section.addEventListener("mouseenter", () => {
            addHoverClass(content, section);
          });
        });

        accordion.forEach((item) => {
          item.addEventListener("click", () => {
            const activeAccordion = content.querySelectorAll(selectors.accordion + ".active");
            if (activeAccordion.length == 0) {
              content.dataset.accordionClose = "true";
              content.classList.remove("mod--essential-hover", "mod--special-hover", "mod--unique-hover");
            } else if (activeAccordion.length == 1) {
              content.removeAttribute("data-accordion-close");
              content.classList.add("mod--one-accordion");
            } else {
              content.removeAttribute("data-accordion-close");
              content.classList.remove("mod--one-accordion");
            }
          });
        });
      });
    };

    init();
  };
  window.addEventListener("load", MybFeatures2);
})();
