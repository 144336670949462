bcl.c.mybContent = {
  props: {
    dynamicClass: ".c-myb-content.dynamicLoading",
    className: "c-myb-content",
    classClientName: "clientName-JS",
    classClientNumber: "clientNumber-JS",
    classMybStays: "mybStays-JS",
    classMybDiscount: "mybDiscount-JS",
    wildCardName: "[name]",
    wildCardNumber: "[clientCode]",
    wildCardStays: "[mybStays]",
    wildCardDiscount: "[mybDiscount]",
    localStorageKey: "userInfo",
  },

  init: function (baseDom) {
    const baseFind = baseDom ? baseDom : document;
    const $component = baseFind.getElementsByClassName(bcl.c.mybContent.props.className);

    if (!$component || !$component[0]) {
      return;
    }

    bcl.u.loadStyleSheet("/etc.clientlibs/barcelo/clientlibs/clientlib-mybContent.css", bcl.c.mybContent.props.dynamicClass);

    bcl.c.mybContent.props.$container = $component;
    Array.from($component).forEach((element) => bcl.c.mybContent.getInfo(element));
  },

  getInfo: function (component) {
    bcl.c.mybContent.updateInfo(component);
  },

  updateDateEditMode: function (component) {
    bcl.c.mybContent.replaceWildCard("$userName", bcl.c.mybContent.props.classClientName, bcl.c.mybContent.props.wildCardName, "[name]", component);
    bcl.c.mybContent.replaceWildCard("$userNumber", bcl.c.mybContent.props.classClientNumber, bcl.c.mybContent.props.wildCardNumber, "[clientCode]", component);
    bcl.c.mybContent.replaceWildCard("$mybStays", bcl.c.mybContent.props.classMybStays, bcl.c.mybContent.props.wildCardStays, "[mybStays]", component);
    bcl.c.mybContent.replaceWildCard("$mybDiscount", bcl.c.mybContent.props.classMybDiscount, bcl.c.mybContent.props.wildCardDiscount, "[mybDiscount]", component);
  },

  updateDateWithoutData: function (component) {
    if (!bcl.profile.mapValues.data) {
      bcl.c.mybContent.replaceWildCard("$userName", bcl.c.mybContent.props.classClientName, bcl.c.mybContent.props.wildCardName, "", component);
      bcl.c.mybContent.replaceWildCard("$userNumber", bcl.c.mybContent.props.classClientNumber, bcl.c.mybContent.props.wildCardNumber, "", component);
      bcl.c.mybContent.replaceWildCard("$mybStays", bcl.c.mybContent.props.classMybStays, bcl.c.mybContent.props.wildCardStays, "", component);
      bcl.c.mybContent.replaceWildCard("$mybDiscount", bcl.c.mybContent.props.classMybDiscount, bcl.c.mybContent.props.wildCardDiscount, "", component);
    }
  },

  updateInfo: function (component) {
    if (!bcl.profile.mapValues.data) {
      bcl.c.mybContent.updateDateWithoutData(component);
      return;
    }
    bcl.c.mybContent.props.user = bcl.profile.mapValues.data;
    if (bcl.c.mybContent.props.user.level < 2) {
      component.classList.add("mod--standard");
    } else if (bcl.c.mybContent.props.user.level === 2) {
      component.classList.add("mod--intense");
    } else if (bcl.c.mybContent.props.user.level > 2) {
      component.classList.add("mod--unique");
    }
    if (bcl.c.mybContent.props.user.name) {
      bcl.c.mybContent.replaceWildCard("$userName", bcl.c.mybContent.props.classClientName, bcl.c.mybContent.props.wildCardName, bcl.c.mybContent.props.user.name, component);
    }
    if (bcl.c.mybContent.props.user.identifier) {
      bcl.c.mybContent.replaceWildCard("$userNumber", bcl.c.mybContent.props.classClientNumber, bcl.c.mybContent.props.wildCardNumber, bcl.c.mybContent.props.user.identifier, component);
    }
    if (bcl.c.mybContent.props.user.bookingNumber != undefined) {
      bcl.c.mybContent.replaceWildCard("$mybStays", bcl.c.mybContent.props.classMybStays, bcl.c.mybContent.props.wildCardStays, bcl.c.mybContent.props.user.bookingNumber, component);
    }
    if (bcl.c.mybContent.props.user.discount != undefined) {
      bcl.c.mybContent.replaceWildCard("$mybDiscount", bcl.c.mybContent.props.classMybDiscount, bcl.c.mybContent.props.wildCardDiscount, bcl.c.mybContent.props.user.discount, component);
    }
  },

  replaceWildCard: function (propertyName, searchClass, wildCard, value, component) {
    if (value == undefined || value == "undefined") {
      value = "";
    }
    const elements = component.getElementsByClassName(searchClass);
    if (elements && elements[0]) {
      bcl.c.mybContent.props[propertyName] = elements[0];
      let text = elements[0].dataset.text;
      text = text.replace(wildCard, value);
      bcl.c.mybContent.props[propertyName].innerHTML = text;
    }
  },
};
