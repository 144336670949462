(() => {
  const MyBDetails = () => {
    const selectors = {
      dynamicClass: ".c-myb-details.dynamicLoading",
      component: "myb-details",
    };

    const init = () => {
      bcl.u.loadStyleSheet("/etc.clientlibs/barcelo/clientlibs/clientlib-myBDetails.css", selectors.dynamicClass);

      const parentElement = Array.from(document.getElementsByClassName(selectors.component));
      if (parentElement) {
        parentElement.forEach((element) => {
          const swiperElement = element?.querySelector(".swiper");
          if (swiperElement && window.innerWidth < 1025) {
            new window.Swiper(element.querySelector(".swiper"), {
              slidesPerView: 1.2,
              freeMode: false,
              loop: false,
              spaceBetween: 30,
              centeredSlides: false,
            });
          }

          window.addEventListener("resize", () => {
            if (swiperElement && window.innerWidth < 1025) {
              new window.Swiper(element.querySelector(".swiper"), {
                slidesPerView: 1.2,
                freeMode: false,
                loop: false,
                spaceBetween: 30,
                centeredSlides: false,
              });
            }
          });
        });
      }
    };
    init();
  };
  document.addEventListener("DOMContentLoaded", MyBDetails);
})();
